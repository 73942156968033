.AdmissionEnquiry{

        
    .auth-form-group-custom {
        position: relative;
    }

    .auth-form-group-custom .form-control {
        height: 60px;
        padding-top: 28px;
        padding-left: 60px;
    }

    .auth-form-group-custom label {
        position: absolute;
        top: 7px;
        left: 60px;
    }

    .auth-form-group-custom .auti-custom-input-icon {
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        left: 19px;
        font-size: 24px;
        color: #FC7F03;
    }

    .cursor-disable{
        cursor:not-allowed;
    }

    .otp-btn{
            position: absolute;
            cursor: pointer;
            top: 11px;
            right: 22px;
        
    }
}