.PhdNotifications{
    a{

        text-decoration:none;
        color:#555
    }

    ol{
        
    }
    
    .blink {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

}

label {

  font-size: 18px;
  font-weight: 500;
  color: black;  
  margin-bottom: 10px;
}

// div.col-md-3, div.col-md-6{

//   margin-top: 20px;
// }