.Brochure{
    .image-source-link {
        color: #98C3D1;
    }
    
    .mfp-with-zoom .mfp-container,
    .mfp-with-zoom.mfp-bg {
        opacity: 0;
        -webkit-backface-visibility: hidden;
        /* ideally, transition speed should match zoom duration */
        -webkit-transition: all 0.3s ease-out; 
        -moz-transition: all 0.3s ease-out; 
        -o-transition: all 0.3s ease-out; 
        transition: all 0.3s ease-out;
    }
    
    .mfp-with-zoom.mfp-ready .mfp-container {
            opacity: 1;
    }
    .mfp-with-zoom.mfp-ready.mfp-bg {
            opacity: 0.8;
    }
    
    .mfp-with-zoom.mfp-removing .mfp-container, 
    .mfp-with-zoom.mfp-removing.mfp-bg {
        opacity: 0;
    }
    
    .events-image img {
        height: 43vh;
    }
}

.photo-gallery img {
    width: 400px;
    height: 337px;
    object-fit: cover;
    // margin-bottom: 2rem;
}

.eventssize {
    width: 1200px;
}

.gallerysize {
    width: 1200px;
}

.hospitalstaff {
    width: 320px;
}