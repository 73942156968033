.customoverlay::after{
    content: "";
    min-height: 100%;
    background: linear-gradient(1deg, black, transparent,transparent);
    position: absolute;
    top:0;
    width: 100%;
}

.carousel-caption{
    z-index: 10;
}