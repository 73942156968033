.Navbar{
    .custom-div-link{
        color: #1b2336;
        border-bottom: 1px dashed #ededed;
        font-size: 13px;
        font-weight: 600;
        line-height: 1;
        position: relative;
        cursor: pointer;
        &:hover{
            color: #FC7F03;
        }
    }
}

