.Objectives{
    .list-wrapper {
        position:relative;
      }
      .list-item-wrapper {
        margin-top:10px;
        position:relative;
      }
      .list-bullet {
        float:left;
        margin-right:20px;
        background:#FC7F03;
        height:30px;
        width:30px;
        line-height:30px;
        border-radius:100px;
        font-weight:700;
        color:white;
        text-align:center;
      }
      .list-item {
        display:table-row;
        vertical-align:middle;
      }
      .list-title {
          font-weight:700;
      }
      .list-text {
          font-weight:400;
      }
      .red-line {
        background:#FC7F03;
        z-index:-1;
        width:1px;
        height:100%;
        position:absolute;
        left:15px;
      }
      .white-line {
        background:#FFF;
        /*top:0px;*/
        width:5px;
        height:100%;
        position:absolute;
        left:13px;
        top:30px;
      }
}

.SankalpVision{
  overflow-x: hidden;
  .react-pdf__Page__canvas{
    margin: auto;
    width: 100vw !important;
    max-width: 600px !important;
    height: auto !important;
  }

  .buttonc{
    button{
      outline: none;
      border: none;
      height: 40px;
      width: 40px;
      line-height: 40px;
      border-radius: 100%;
      background-color: #FC7F03;
      color: #fff;
      font-size: 20px;
      text-align: center;
    }
  }
}

.cnt.left .date {
  right: -148px !important;
}