tbody, td, tfoot, th, thead, tr {
    border-color: inherit;
    border-style: solid;
    border-width: 0;
}
*, ::after, ::before {
    box-sizing: border-box;
}
user agent stylesheet
tr {
    display: table-row;
    vertical-align: inherit;
    unicode-bidi: isolate;
    border-color: inherit;
}

table th {
    font-size: 18px !important;
    padding: 5px;
}

table td {
    font-size: 15px;
    padding: 5px !important;
}

table{
    margin-left: 40px !important;
    width: 800px !important;
}

.AISHE {
  width: 96% !important;
  padding: 0px !important;
}

.AISHE td {
    font-size: 16px !important;
}