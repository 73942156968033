.course-section {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.wrap {
  display: flex;
  background: linear-gradient(45deg, #95172b,#E32545);
  padding: 1rem 1rem 1rem 1rem;
  box-shadow: 7px 7px 30px -5px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
  cursor: pointer;
  transition: 0.3s ease all;
  border-radius: 25px;
}

.wrap:hover {
  background:linear-gradient(45deg, #7b1525,#E32545);
  color: white !important;
  box-shadow: 0px 4px 5px 0px #888888;
}

.sub-section-title {
  margin: 5% 0% 3% 0%;
  width: 100%;
  text-align: center;
}

.ico-wrap {
  margin: auto;
}

.mbr-iconfont {
  font-size: 4.5rem !important;
  color: #313131;
  margin: 1rem;
  padding-right: 1rem;
}
.vcenter {
  margin: auto;
}

.mbr-section-title3 {
  text-align: left;
}
h2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.display-5 {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1.4rem;
}
.mbr-bold {
  font-weight: 700;
}

p {
  padding-top: 5px;
  padding-bottom: 2px;
  line-height: 18px;
}
.display-6 {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1rem;
}

@media only screen and (max-width: 600px) {
  .mbr-iconfont {
    font-size: 2.5rem !important;
  }
  .display-5{
    font-size: 0.9rem;
  }
  .display-6{
    font-size: 0.7rem;
  }
}