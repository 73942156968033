.image-container{
    height: 12em;
    width: 10em;
    border: 1px solid #000;
    display: flex;
    justify-content: center;
    align-items: center;
}

section{
    padding: 3em;
}

@media print {
    
    @page{
        size: A4;  
        margin:3em;   
    }
    
  }
  