.Home{
    .countDown{
        background-color: #011327;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        
    }
    .item{
        background-color: #02244a;
        color: white;
    
        font-size: 1.5vw;
        text-align: center;
        font-weight: 100;
        font-family: roboto;
    }
    .column{
        display:flex;
        flex-direction: column;
        
    }
    .text{
        
        height: 15vw;
        text-align: center;
        font-size: 1vw;
        font-family: roboto;
        font-weight: 200;
        color: white;
        text-align: center;
        width: 100%;
    }
    .column{
        background-color: #02244a;
        height: 4vw;
        width: 4vw;
        margin: .5vw;
    }
    
    .blink_me {
        animation: blinker 1s linear infinite;
        color:#FC7F03;
        }

@keyframes blinker {
50% {
opacity: 0;
}
}
}


.main-slider {
  height: 82vh;
  object-fit: fill !important;
  // object-position: center !important;
}

@media (max-width: 767px) { /* Adjust height for smaller screens */
    .main-slider {
      height: 38vh; /* Adjust as needed */
    }
  }

  .popup {
    height: 66vh;
    object-fit: fill;
  }
  
  @media (max-width: 767px) { /* Adjust height for smaller screens */
      .popup {
        height: 38vh; /* Adjust as needed */
      }
    }


    //swiper

    .swiper {
      width: 100%;
      // height: 100%;
      height: 83vh;
    }
    
    .swiper-slide {
      text-align: center;
      font-size: 18px;
      background: #fff;
    
      /* Center slide text vertically */
      display: flex;
      justify-content: center;
      align-items: center;
      
    }

    .swiper-button-next {
      right: var(--swiper-navigation-sides-offset, 60px) !important;
      color: #fff !important;
    }

    .swiper-button-prev {
      left: var(--swiper-navigation-sides-offset, 60px) !important;
      color: #fff !important;
    }

    @media (max-width: 767px) {
    .swiper-button-next {
      right: var(--swiper-navigation-sides-offset, 60px) !important;
      color: #fff !important;
      height: 20vh;
      width: 30vh;
    }
    }

    @media (max-width: 767px) {
    .swiper-button-prev {
      left: var(--swiper-navigation-sides-offset, 60px) !important;
      color: #fff !important;
    }
    }

    .swiper-pagination-bullet-active {
      color: #fff !important;
    }

    .swiper-slide .main-slider {
      display: block;
      width: 100% !important;
      height: 84vh;
      // object-fit: cover !important;
      // object-position: center !important;
      object-fit: fill !important;
  }

  .swiper-slide .PopUp {
    display: block;
    height: 74vh !important;
    object-fit: contain !important;
    width: 100%;
}

.img-fluid {
    max-width: 100%;
    height: auto;
}

    @media (max-width: 767px) { /* Adjust height for smaller screens */
      .swiper-slide .main-slider {
        height: 38vh !important; /* Adjust as needed */
        object-fit: fill !important;
      }
    }

    @media (max-width: 767px) { /* Adjust height for smaller screens */
      .swiper-slide .PopUp {
        height: 38vh !important; /* Adjust as needed */
        object-fit: fill !important;
      }
    }

    @media (max-width: 767px) { /* Adjust height for smaller screens */
      .swiper {
        height: 38vh; /* Adjust as needed */
        object-fit: fill;
      }
    }

    .img-fluid {
      max-width: 100%;
      // height: auto;
  }

  .banner-area {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }


  //
  .homeomisionimg {
    height: 560px !important;
  }

  .visionhomeoimg {
    height: 350px !important;
  }

  .missionpharm {
    height: 416px !important;
  }

  .modal-footer {
    display: flex;
    flex-shrink: 0;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap)* .2) !important;
    background-color: var(--bs-modal-footer-bg);
    border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
    border-bottom-right-radius: var(--bs-modal-inner-border-radius);
    border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}

.pop-up {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
  height: 660px !important;
  width: 100% !important;
  object-fit: fill !important;
}

.popup-content{
  position: relative;
  display: flex;
  flex-direction: column;
  width: 96% !important;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
  height: 750px !important;
}
