.OfficerProfile{
    .blockquote {
        padding: 60px 80px 40px;
        position: relative;
    }
    
    .blockquote p {
        font-family: "Utopia-italic";
        font-size: 35px;
        font-weight: 700px;
        text-align: center;
    }
    
    .blockquote::after {
        content: "";
        top: 20px;
        left: 50%;
        margin-left: -100px;
        position: absolute;
        border-bottom: 3px solid #bf0024;
        height: 3px;
        width: 200px;
    }
    
    @import url(https://fonts.googleapis.com/css?family=Open+Sans:400italic);
    .otro-blockquote {
        font-size: 1.2em;
        width: 80%;
        margin: 50px auto;
        /* font-family: Open Sans; */
        font-style: italic;
        color: #151515;
        padding: 1.2em 30px 1.2em 75px;
        border-left: 8px solid #ff0000;
        line-height: 1.6;
        position: relative;
        background: #EDEDED;
    }
    
    .otro-blockquote::before {
        font-family: Arial;
        content: "\201C";
        color: #ff0000;
        font-size: 4em;
        position: absolute;
        left: 10px;
        top: -10px;
    }
    
    .otro-blockquote::after {
        content: '';
    }
    
    .otro-blockquote span {
        display: block;
        color: #333333;
        font-style: normal;
        font-weight: bold;
        margin-top: 1em;
    }
}

.otro-blockquotes {
    font-size: 1.2em;
    width: 80%;
    margin: 50px auto;
    /* font-family: Open Sans; */
    font-style: italic;
    color: #151515;
    padding: 1.2em 30px 1.2em 75px;
    border-left: 8px solid #ff0000;
    line-height: 1.6;
    position: relative;
    background: #EDEDED;
}

.commerce-princi {
    height: 350px;
}