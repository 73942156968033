.table th {

    vertical-align: middle
}

.staffdetails {
    width: 100%;
    height: 160px;
    object-fit:fill;
    object-position: top;
    // object-position:0%;
}