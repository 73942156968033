.GoverningBody{
    .officers .nav-link.active {
       border-radius: 20px;
       background-color: #ff000021;
       color: #FC7F03;
       font-weight: 600;
   }
   
   .single_advisor_profile {
       position: relative;
       margin-bottom: 50px;
       -webkit-transition-duration: 500ms;
       transition-duration: 500ms;
       z-index: 1;
       border-radius: 15px;
       -webkit-box-shadow: 0 0.25rem 1rem 0 rgba(47, 91, 234, 0.125);
       box-shadow: 0 0.25rem 1rem 0 rgba(47, 91, 234, 0.125);
       min-height: 95%;
   }
   
   .single_advisor_profile .advisor_thumb {
       position: relative;
       z-index: 1;
       border-radius: 15px 15px 0 0;
       margin: 0 auto;
       /* padding: 30px 30px 0 30px; */
       background-color: #FC7F03;
       overflow: hidden;
   }
   
   .single_advisor_profile .advisor_thumb::after {
       -webkit-transition-duration: 500ms;
       transition-duration: 500ms;
       position: absolute;
       width: 150%;
       height: 80px;
       bottom: -45px;
       left: -25%;
       content: "";
       background-color: #ffffff;
       -webkit-transform: rotate(-15deg);
       transform: rotate(-15deg);
   }
   
   @media only screen and (max-width: 575px) {
       .single_advisor_profile .advisor_thumb::after {
           height: 160px;
           bottom: -90px;
       }
   }
   
   .single_advisor_profile .advisor_thumb .social-info {
       position: absolute;
       z-index: 1;
       width: 100%;
       bottom: 0;
       right: 30px;
       text-align: right;
   }
   
   .single_advisor_profile .advisor_thumb .social-info a {
       font-size: 14px;
       color: #020710;
       padding: 0 5px;
   }
   

   .single_advisor_profile .advisor_thumb .social-info a:hover,
   .single_advisor_profile .advisor_thumb .social-info a:focus {
       color: #FC7F03;
   }
   
   .single_advisor_profile .advisor_thumb .social-info a:last-child {
       padding-right: 0;
   }
   
   .single_advisor_profile .single_advisor_details_info {
       position: relative;
       z-index: 1;
       padding: 25px !important;
       height: 80% !important;
       text-align: right;
       -webkit-transition-duration: 500ms;
       transition-duration: 500ms;
       border-radius: 0 0 15px 15px;
   }
   
   .single_advisor_profile .single_advisor_details_info::after {
       -webkit-transition-duration: 500ms;
       transition-duration: 500ms;
       position: absolute;
       z-index: 1;
       width: 50px;
       height: 3px;
       background-color: #FC7F03;
       content: "";
       top: 12px;
       right: 30px;
   }
   
   .single_advisor_profile .single_advisor_details_info h6 {
       margin-bottom: 0.25rem;
       -webkit-transition-duration: 500ms;
       transition-duration: 500ms;
   }
   
   @media only screen and (min-width: 768px) and (max-width: 991px) {
       .single_advisor_profile .single_advisor_details_info h6 {
           font-size: 14px;
       }
   }
   
   .single_advisor_profile .single_advisor_details_info p {
       -webkit-transition-duration: 500ms;
       transition-duration: 500ms;
       margin-bottom: 0;
       font-size: 14px;
   }
   
   @media only screen and (min-width: 768px) and (max-width: 991px) {
       .single_advisor_profile .single_advisor_details_info p {
           font-size: 12px;
       }
   }
   
   .single_advisor_profile:hover .advisor_thumb::after,
   .single_advisor_profile:focus .advisor_thumb::after {
       background-color: #FC7F03;
   }
   
   .single_advisor_profile:hover .advisor_thumb .social-info a,
   .single_advisor_profile:focus .advisor_thumb .social-info a {
       color: #ffffff;
   }
   
   .single_advisor_profile:hover .advisor_thumb .social-info a:hover,
   .single_advisor_profile:hover .advisor_thumb .social-info a:focus,
   .single_advisor_profile:focus .advisor_thumb .social-info a:hover,
   .single_advisor_profile:focus .advisor_thumb .social-info a:focus {
       color: #ffffff;
   }
   
   .single_advisor_profile:hover ,
   .single_advisor_profile:focus  {
       background-color: #FC7F03;
   }
   
   .single_advisor_profile:hover .single_advisor_details_info::after,
   .single_advisor_profile:focus .single_advisor_details_info::after {
       background-color: #ffffff;
   }
   
   .single_advisor_profile:hover .single_advisor_details_info h6,
   .single_advisor_profile:focus .single_advisor_details_info h6 {
       color: #ffffff;
   }
   
   .single_advisor_profile:hover .single_advisor_details_info p,
   .single_advisor_profile:focus .single_advisor_details_info p {
       color: #ffffff;
   }
   
   .single_advisor_profile:hover .single_advisor_details_info p a,
   .single_advisor_profile:focus .single_advisor_details_info p a {
       color: #ffffff;
   }
   
   .single_advisor_details_info a {
       text-decoration: none;
       color: #020710;
   }
}


.GoverningBody .single_advisor_profile .advisor_thumb::after {
transition-duration: 500ms;
position: absolute;
width: 150%;
height: 56px !important;
bottom: -45px;
left: -25%;
content: "";
background-color: #ffffff;
transform: rotate(-1deg) !important;
}