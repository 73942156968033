.campusplace {
    object-fit: cover;
    object-position: top;
}

.row>* {
    // margin-bottom: 42px;
    flex-shrink: 0;
    padding-right: calc(var(--bs-gutter-x)* .5);
    padding-left: calc(var(--bs-gutter-x)* .5);
    margin-top: var(--bs-gutter-y);
}

.campusplace {
    background: #fff;
    border-radius: 10px;
    // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
    overflow: hidden;
  }
  
  .campusplace img {
    // border-radius: 10px 10px 0 0;
    object-fit: cover;
    object-position: center;
  }
  
  .campusplace h6,
  .campusplace p {
    margin: 10px 0;
    margin-left: 10px;
  }
  
  .campusplace h6 {
    font-size: 18px;
    font-weight: bold;
  }
  
  .campusplace p {
    font-size: 16px;
    color: #555;
  }
  
  .campusplace p strong {
    color: #333;
  }

.campusplace:hover {
  transform: translateY(-10px);
//   box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}


