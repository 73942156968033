.Graduate{
    .icon{
        width:60%;
        /* padding-top:10px */
       
    }

    .modal_icon{
        width: 120px;
        margin-right:50px

    }

    i{

        vertical-align: middle;
    }
   


.w-100{
  width:200%!important
}

li .crs{

  font-size:1.2rem;

  line-height:4
} 
}