.left-right-arrows {
  display: flex;
  justify-content: end;
  margin: 2em 0;
  gap: 0.7em;
}

.section-heading {
  background: #FC7F03;
  color: #fff;
  padding: 0.5em 0em 0em 0.5em;
}

.left-right-arrows > button {
  width: 4em;
  height: 4em;
  background: #FC7F03;
  color: #fff;
  border: 0px;
  box-shadow: 2px 2px 2px 1px rgb(0 0 0 / 20%);
}

.meta {
  font-family: Helvetica, Arial, open-sans;
  font-size: 0.8571rem;
  font-weight: 600;
  color: #0093e0 !important;
}

@media (max-width: 991px) {
  .announcements h2 {
    border-bottom: none;
  }
}

@media (max-width: 991px) {
  .announcements h2.events-heading {
    padding-left: 15px;
    margin-top: 20px;
  }
}

.announcements .slick-slider ul {
  font-family: "Open Sans Condensed", Arial, Verdana, sans-serif;
}

.announcements .slick-slider ul li:last-child {
  border-bottom: none;
  margin-bottom: 10px;
}

.announcements .slick-slider ul li h3:hover a {
  color: #7d3793;
  text-decoration: underline;
}

.announcements .slick-slider ul p {
  font-size: 1.2rem;
  font-family: "Helvetica Neue", Helvetica, Arial, open-sans;
}

.announcements .slick-slider .all {
  color: #0093e0 !important;
  font-family: "Helvetica Neue", Helvetica, Arial, open-sans;
}

.announcements .slick-slider .all:hover {
  border-bottom: none;
}

.announcements .slick-slider .all .fa-caret-right {
  padding-left: 6px;
  position: relative;
}

.announcements .slick-slider .all .fa-caret-right:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 6px;
  bottom: -4px;
  right: 0;
  background-color: #fff;
}

.announcements .left-right-arrows button:first-child {
  margin-right: 3px;
}

@media (max-width: 991px) {
  .announcements {
    margin: 0 auto;
    width: 70%;
  }
}

@media (max-width: 767px) {
  .announcements {
    width: 80%;
  }
}

@media (max-width: 500px) {
  .announcements {
    width: 100%;
  }
}

/* .left-right-arrows {
  position: absolute;
  bottom: 0px;
  right: 10px;
} */

@media (max-width: 767px) {
  .left-right-arrows {
    border-top: -21px;
  }
}

@media (min-width: 360px) and (max-width: 767px) {
  .left-right-arrows button {
    height: 50px;
    width: 50px;
  }
}

.events-section {
  position: relative;
  margin: 0 auto;
}

@media (max-width: 991px) {
  .events-section {
    width: 70%;
  }
}

@media (max-width: 767px) {
  .events-section {
    width: 80%;
  }
}

@media (max-width: 500px) {
  .events-section {
    width: 100%;
  }
}

.events-section .slick-slider ul {
  font-family: "Open Sans Condensed", Arial, Verdana, sans-serif;
}

.events-section .slick-slider ul li {
  padding: 1rem 0;
  position: relative;
}

.events-section .slick-slider ul li:last-child {
  border-bottom: none;
  margin-bottom: 10px;
}

@media (max-width: 767px) {
  .events-section .slick-slider ul li:after {
    width: 80px;
  }
}

.events-section .slick-slider ul li:hover .events-date {
  background-color: #461e52;
  color: #fff;
}

.events-section .slick-slider ul li:hover .event-li h3 {
  color: #0093e0 !important;
}

.events-section .slick-slider ul li .event-li {
  display: inline-block;
  padding-left: 20px;
}

@media (max-width: 767px) {
  .events-section .slick-slider ul li .event-li {
    padding-left: 15px;
  }
}

.events-section .slick-slider ul li .event-li h3 {
  display: inline-block;
  margin-bottom: 8px;
}

@media (max-width: 767px) {
  .events-section .slick-slider ul li .event-li h3 {
    font-size: 1.429rem;
  }
}

.events-section .slick-slider ul li .event-li p {
  font-size: 1.214rem;
  font-weight: 600;
  color: #000;
  font-family: "Helvetica Neue", Helvetica, Arial, open-sans;
}

@media (max-width: 767px) {
  .events-section .slick-slider ul li .event-li p {
    font-size: 16px;
    /*font-size: 1.6rem;*/
  }
}

.events-section .slick-slider .all {
  color: #461e52;
  font-family: "Helvetica Neue", Helvetica, Arial, open-sans;
}

.events-section .slick-slider .all:hover {
  border-bottom: none;
}

.events-section .slick-slider .all .fa-caret-right {
  padding-left: 6px;
  position: relative;
}

.events-section .slick-slider .all .fa-caret-right:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 6px;
  bottom: -4px;
  right: 0;
  background-color: #fff;
}

.events-section .slick-slider .events-date {
  min-width: 50px;
  height: 50px;
  background-color: #FC7F03 !important;
  font-family: "Helvetica Neue", Helvetica, Arial, open-sans;
  font-weight: 600;
  padding-top: 3px;
  display: inline-block;
  transition: all, 0.4s, ease;
}

@media (max-width: 767px) {
  .events-section .slick-slider .events-date {
    width: 61px;
    height: 68px;
    padding-top: 10px;
  }
}

.events-section .slick-slider .events-date a:focus,
.events-section .slick-slider .events-date a:hover {
  color: #fff;
}

.events-section .slick-slider .events-date span {
  font-weight: 300;
  font-size: 1.2rem;
  display: block;
  padding: 0;
  line-height: 30px;
}

@media (max-width: 767px) {
  .events-section .slick-slider .events-date span {
    font-size: 1.429rem;
  }
}

@media (min-width: 991px) {
  .events-section .left-right-arrows {
    bottom: 0px;
  }
}

.viewimage {
  height: 500px !important;
  width: 720px !important;
  object-fit: fill;
  align-content: center;
}

:where(.css-dev-only-do-not-override-usln0u).ant-modal .ant-modal-content {
  position: relative;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 0;
  width: 740px;
  height: 700px !important;
  border-radius: 8px;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  pointer-events: auto;
  padding: 6px !important;
}

.pr-20 {
  padding-right: 0px !important;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .meetings {
      padding-bottom: 2px !important;
      margin-bottom: 2px !important;
  }
}
.meetings {
  padding-bottom: 2px !important;
  border-bottom: 1px solid #f4f6f9;
  margin-bottom: 2px !important;
}
