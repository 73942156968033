.table>:not(caption)>*>*{
    vertical-align: middle!important;
    padding: 0.1rem 0.2rem!important;
       

}

.result {

    border-color: #2a2b2c!important;
}

table {
    margin-left: 0 !important;
    width: 100% !important;
}
@media only screen and (max-width: 480px) {
    /* horizontal scrollbar for tables if mobile screen */
    .table {
        overflow-x: auto;
        display: block;
    }
    .table th {

        font-size: 0.8rem !important;
        font-weight:600
    }
    .table td {

        font-size: 0.8rem !important;
        
    }
}

    @media print {
        table {
           
            width: 800px !important;
        }

        .clg-details{

           text-align: center;
        }
        .logo{
            width: 20%;
        }
        
        .clg{
            font-size: 25px;
        }
        .stmt{
            font-size: 1.1rem; 
            text-align: center;
      
        }
        .table th {

            font-size: 0.9rem !important;
            font-weight:600
        }
        .table td {

            font-size: 0.8rem;
        }
        }